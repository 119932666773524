import { http } from '@/services/http.service';
import { IApiResponse } from '@/types/general';

export const getFeatureFlags = async (): Promise<IApiResponse[]> => {
  const {
    data: { data },
  } = await http.get(`/api/feature-flags`);

  return <IApiResponse[]>data;
};
