import { Component, createApp } from 'vue';
import { Router } from 'vue-router';
import { VueQueryPlugin } from 'vue-query';
import { VueCsvImportPlugin } from 'vue-csv-import';
import { createHead } from '@unhead/vue';
import { globalProperties } from './globalProperties';
import { pinia } from '@/stores';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { setHTTPHeader } from '@/services/http.service';
import AuthService from '@/services/auth.service';
import PageHeader from '@/components/PageHeader.vue';
import * as Sentry from "@sentry/vue";

const token = AuthService.getToken();

if (token) {
  setHTTPHeader({ Authorization: token });
}

const activeCreator = AuthService.getActiveCreator();
if (activeCreator) {
  setHTTPHeader({ 'X-W-C-Creator-Id': activeCreator.id });
}

export const setupEntryPoint = (rootComponent: Component, router: Router) => {
  const app = createApp(rootComponent);

  Sentry.init({
    app,
    dsn: "https://41018e5aff7540838d04b71f3c56a116@o4507969465352192.ingest.us.sentry.io/4507969470529536",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "127.0.0.1", /^https:\/\/usecr8\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const head = createHead();

  const GoogleCaptchaSiteKey = typeof(gon) !== 'undefined' ? gon?.recaptcha_v3_site_key : ''

  app.use(head);
  app.use(router);
  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(VueCsvImportPlugin);
  app.use(VueReCaptcha, { siteKey: GoogleCaptchaSiteKey })
  app.config.globalProperties = { ...app.config.globalProperties, ...globalProperties };

  app.component('page-header', PageHeader);

  app.mount('#app');
};
