import { http } from '@/services/http.service';
import { IApiResponseStats, ICreator } from '@/types/general';

export const getCreators = async (): Promise<ICreator[]> => {
  const {
    data: { data },
  } = await http.get(`/api/creators`);

  return <ICreator[]>data;
};

export const getCreatorsCreatorHandleExists = async (handle: string): Promise<string> => {
  const {
    data: { data },
  } = await http.get(`/api/creators/creator-handle-exists?handle=${handle}`);

  return <string>data;
};

export const postCreators = async (creator): Promise<ICreator> => {
  const {
    data: { data },
  } = await http.post(`/api/creators`, creator);

  return <ICreator>data;
};

export const getCreatorsStats = async (): Promise<IApiResponseStats> => {
  const {
    data: { data },
  } = await http.get(`/api/creators/stats`);

  return <IApiResponseStats>data;
};
