import { http, setHTTPHeader } from './http.service';
import { IUserLogin, IRegisterUser } from '@/types/general';
import { login, logout, register, getCurrentUser, getAccountBalances } from '@/apis/auth.api';
import { getCreators } from '@/apis/creators.api';
import { getFeatureFlags } from '@/apis/feature_flags.api';

class AuthService {
  async login(user: IUserLogin) {
    return login(user).then(async (response) => {
      if (!response?.headers?.authorization) {
        console.error("Login request failed to return authorisation header", { response })
        return false
      }
      
      const token = response.headers.authorization;
      localStorage.setItem('user', JSON.stringify(response.data.data));
      localStorage.setItem('token', token);
      setHTTPHeader({ Authorization: token });

      // Refresh Feature Flags
      getFeatureFlags().then((flags) => {
        localStorage.setItem('feature-flags', JSON.stringify(flags));
      });

      // Fetch all Creators and set the first one as active
      return getCreators().then((creators) => {
        localStorage.setItem('creators', JSON.stringify(creators));
        if (creators.length > 0) {
          this.setActiveCreator(creators[0]);
        }

        return response.data;
      });
    });
  }

  async logout() {
    return logout()
      .then(() => {
        this.clearCache()
        this.redirectToLogin()
      })
      .catch((e) => {
        console.error({ e })
        alert('An error occurred while logging you out. Kindly try again later')
      });
  }

  async register(user: IRegisterUser) {
    return register(user);
  }

  clearCache(): void {
    delete http.defaults.headers.common['Authorization'];

    ['active_creator_id', 'creators', 'feature-flags', 'token', 'user'].forEach((key) =>
      localStorage.removeItem(key),
    );
  }
  
  redirectToLogin(): void {
    if (location.href != '/login') {
      location.href = '/login';
    }
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (user) {
      try {
        // Place call to API
        // If this fails the user will need to reauthenticate
        this.getUserFromServer();

        const parsed = JSON.parse(user);
        return parsed;
      } catch (e) {
        return null;
      }
    }

    return null;
  }

  async getUserFromServer() {
    return await getCurrentUser()
      .then((data) => data.data)
      .catch(() => this.clearCache());
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getCreators() {
    const result = JSON.parse(localStorage.getItem('creators') || '[]');
    return result;
  }

  getFeatureFlags() {
    const result = JSON.parse(localStorage.getItem('feature-flags') || '[]');
    return result;
  }

  checkFeatureFlagIsEnabled(flag: string) {
    const flags = this.getFeatureFlags();
    return flags.includes(flag);
  }

  setActiveCreator(creator) {
    if (creator?.id) {
      localStorage.setItem('active_creator_id', creator.id);
    }
  }

  getActiveCreator() {
    const creators = this.getCreators();
    const active_creator_id = localStorage.getItem('active_creator_id');
    if (creators.length && active_creator_id) {
      const result = creators.find((creator) => creator.id == active_creator_id);
      return result;
    }
    return false;
  }
}

const instance = new AuthService();

export default instance;
